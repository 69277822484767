<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="物业用户Id，sys_user.id" prop="sysUserId" >-->
<!--        <a-input v-model="form.sysUserId" placeholder="请输入物业用户Id，sys_user.id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="名称" prop="title" >
        <a-input v-model="form.title" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="小区区域" prop="cityArray">

        <a-cascader :options="cityList" :changeOnSelect = true
                    v-model='form.cityArray'
                    :field-names="{ label: 'areaName', value: 'areaName', children: 'children' }"
                    placeholder="请选择" @change="onChange" />
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address" >
        <a-input v-model="form.address" placeholder="请输入详细地址" />
      </a-form-model-item>
<!--      <a-form-model-item label="经度" prop="lng" >-->
<!--        <a-input v-model="form.lng" placeholder="请输入经度" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="纬度" prop="lat" >-->
<!--        <a-input v-model="form.lat" placeholder="请输入纬度" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="小区物业电话" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入小区物业电话" />
      </a-form-model-item>

      <a-form-model-item label="是否开放论坛" prop='isDynamic' :required="true">
        <a-select placeholder="请选择" v-model="form.isDynamic" >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="论坛是否审核" prop="isApproveDynamic" >
        <a-select placeholder="请选择" v-model="form.isApproveDynamic" >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>

      </a-form-model-item>
      <a-form-model-item label="论坛订单是否审核" prop="isApproveOrder" >
        <a-select placeholder="请选择" v-model="form.isApproveOrder" >
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>

      </a-form-model-item>
      <a-form-model-item label="门店描述" prop="desc" >
        <a-input v-model="form.desc" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVillage, addVillage, updateVillage } from '@/api/village/village'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      cityArray:[],
      defaultCity:[],
      // 表单参数
      form: {
        id: null,

        sysUserId: null,

        title: null,

        areap: null,

        areac: null,

        areax: null,

        address: null,

        lng: null,

        lat: null,

        mobile: null,

        isDynamic: null,

        isApproveDynamic: null,
        isApproveOrder:null,
        desc: null,

        createTime: null,
        cityArray:[],
        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isDynamic: [
          { required: true, message: '是否开放论坛不能为空', trigger: 'blur' }
        ],
        isApproveDynamic: [
          { required: true, message: '论坛内容是否审核不能为空', trigger: 'blur' }
        ],
        isApproveOrder: [
          { required: true, message: '论坛订单是否审核不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    cityList() {

      return this.$store.getters.acityList;
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onChange(value) {
      this.defaultCity = value;
      this.form.cityArray = value
      console.log("==============",this.defaultCity)
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.defaultCity = []

      this.form = {
        id: null,
        sysUserId: null,
        title: null,
        areap: null,
        areac: null,
        areax: null,
        address: null,
        lng: null,
        lat: null,
        mobile: null,
        isDynamic: null,
        isApproveDynamic: null,
        isApproveOrder:null,
        desc: null,
        createTime: null,
        cityArray:[],
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVillage({"id":id}).then(response => {
        this.form = response.data
        this.form.cityArray = [this.form.areap,this.form.areac,this.form.areax]
        this.open = true
        this.formTitle = '修改'
      })

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.areap =  this.form.cityArray[0];
          this.form.areac =  this.form.cityArray[1];
          this.form.areax =  this.form.cityArray[2];
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVillage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVillage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
